"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineViews = exports.routes = void 0;
const home_page_1 = require("../main/home-page");
const install_page_1 = require("../install/install-page");
const InstallConstant = require("../install/constants");
exports.routes = [
    {
        path: '/web',
        tag: 'home-page',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${InstallConstant.routerPathGroup}`,
        tag: 'install-page',
        pathParamNames: [],
        queryParamNames: [],
    },
];
const defineViews = () => {
    (0, home_page_1.defineHomePage)('home-page');
    (0, install_page_1.defineInstallPage)('install-page');
};
exports.defineViews = defineViews;
